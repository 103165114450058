.App {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

}

.snakeContainer {
  height: 800px;
  max-height: 100vh;

  width: 400px;
  max-width: 100vw;
}

.App,
.snakeContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
