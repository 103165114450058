.snake {
    color: #61dafb;
    display: flex;
    flex-direction: column;

    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.snake__view,
.snake__controlsWrapper {
    flex: 1;
}

.snake__view {
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #282c34;
}

.snake__controlsWrapper {
}

.snake__controls {
    display: grid;

    height: 100%;
    width: 100%;

    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;

    transform: rotate(45deg);

    border-radius: 100%;
    overflow: hidden;
    border: 1px solid black;
}

.snakeControls__button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ff9fd1;
}

.snakeControls__button svg {
    height: 30%;
    width: 30%;
    pointer-events: none;
}

.snakeControls__up{
    border-bottom: 1px solid black;
    border-right: 1px solid black;
}

.snakeControls__right{
    border-bottom: 1px solid black;
    border-left: 1px solid black;
}

.snakeControls__down {
    border-top: 1px solid black;
    border-left: 1px solid black;
}

.snakeControls__left {
    border-top: 1px solid black;
    border-right: 1px solid black;
}

.snakeControls__up svg {
    transform: rotate(-45deg);
}

.snakeControls__right svg {
    transform: rotate(45deg);
}

.snakeControls__down svg {
    transform: rotate(135deg);
}

.snakeControls__left svg {
    transform: rotate(-135deg);
}



.snake__view,
.snake__grid {
    height: 100%;
    width: 100%;
}

.snake__view {
    position: relative;
}

.snake__grid {
    display: grid;
}

.snakeView__square {
    display: flex;
    align-items: center;
    justify-content: center;
}

.snakeView__square--food {
    background-color: red;
}

.snakeView__square--snake {
    background-color: green;
}

.snake__deadMessage {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    align-items: center;
    justify-content: center;
}

.snake__points {
    position: absolute;
    bottom: -40px;


    color: #282c34;
    font-size: 20px;
}

.snake__points--left {
    left: 10px;
}
.snake__points--right {
    right: 10px;
}